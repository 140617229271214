import axios from 'axios'
import swal from 'sweetalert'

class JournalFactory {

  async get (data: any) {
    try {
      let url = '/acc/journals'
      var response = await axios.get(url, {
        params: {
          reff_no: data.reff_no,
          acc_account_id: data.acc_account_id,
          date_from: data.date_from,
          date_to: data.date_to,
          offset: data.offset,
          limit: data.limit,
          order_by: data.order_by,
          order_at: data.order_at
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async search (keyword:string) {
    try {
      let url = '/acc/journals/search'
      var params = { keyword: keyword }
      var response = await axios.get(url, { params: params })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async detail (id: any) {
    try {
      let url = `/acc/journals/${id}`
      var response = await axios.get(url)
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async create (data: any) {
    try {
      let url = '/acc/journals'
      var response = await axios.post(url, data)
      return response.data
    } catch (error) {
      if (error instanceof Error){
        if ('response' in error) {
          var messages: Array<string> = error['response']['data']['messages']
          var status = error['response']['data']['status']
          if (status == 500) {
            swal("Terjadi Kesalahan", "Terjadi Kesalahan Pada Server", "error")
          } else {
            swal(messages.join(', '), "", "error")
          }
          console.error(error)
        }
      }
      return false
    }
  }

  async update (id: any, data: any) {
    try {
      let url = `/acc/journals/${id}`
      var response = await axios.put(url, data)
      return response.data
    } catch (error) {
      if (error instanceof Error){
        if ('response' in error) {
          var messages: Array<string> = error['response']['data']['messages']
          var status = error['response']['data']['status']
          if (status == 500) {
            swal("Terjadi Kesalahan", "Terjadi Kesalahan Pada Server", "error")
          } else {
            swal(messages.join(', '), "", "error")
          }
          console.error(error)
        }
      }
      return false
    }
  }

  async delete (id: any) {
    try {
      let url = `acc/journals/${id}`
      var response = await axios.delete(url)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async bulkDelete (ids: Array<number>) {
    try {
      let url = `acc/journals/bulk_delete`
      var response = await axios.post(url, {ids: ids})
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async export (data: any) {
    try {
      let url = '/acc/journals/export'
      var response = await axios.get(url, {
          responseType: 'arraybuffer',
          params: {
            reff_no: data.reff_no,
            acc_account_id: data.acc_account_id,
            status: data.status,
            date_from: data.date_from,
            date_to: data.date_to,
            order_by: data.order_by,
            order_at: data.order_at,
        }
      })
      let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Ekspor_Jurnal.xlsx'
      link.click()
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async importJournals (data: any) {
    try {
      let url = '/acc/journals/import'
      var response = await axios.post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  // Journal Mapping
  async getMaps () {
    try {
      let url = '/acc/journal_maps'
      var response = await axios.get(url, {
        params: {
          // For later
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async createMap (data: any) {
    try {
      let url = 'acc/journal_maps'
      var response = await axios.post(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async updateMap (id: any, data: any) {
    try {
      let url = `acc/journal_maps/${id}`
      var response = await axios.put(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }
  // End of: Journal Mapping

  // Journal Mapping 2
  async getMaps2type () {
    try {
      let url = '/acc/journal_map2_types'
      var response = await axios.get(url, {
        params: {
          // For later
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async getMaps2 () {
    try {
      let url = '/acc/journal_maps2'
      var response = await axios.get(url, {
        params: {
          active: true
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async getMap2Detail (id:number) {
    try {
      let url = '/acc/journal_maps2/' + id
      var response = await axios.get(url, {
        params: {
          // For later
        }
      })
      return response.data
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async createMap2 (data: any) {
    try {
      let url = 'acc/journal_maps2'
      var response = await axios.post(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async updateMap2 (id: any, data: any) {
    try {
      let url = `acc/journal_maps2/${id}`
      var response = await axios.put(url, data)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }

  async deleteMap2 (id: any) {
    try {
      let url = `acc/journal_maps2/${id}`
      var response = await axios.delete(url)
      return response.data
    } catch (error) {
      console.error(error)
      return error
    }
  }
  // End of: Journal Mapping 2

}

export let journalFactory = new JournalFactory()
